import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/client/components/BadgeText.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/client/components/BottomProfile/Header/CloseButtonWrap.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/client/components/BottomProfile/Header/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/client/components/BottomProfile/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/client/components/BottomProfile/ToggleList/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/client/components/ChatLink.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/client/components/Footer/FormCheckboxContent/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/client/components/Footer/LanguageSwitcher/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/client/components/Footer/NavItem/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/client/components/LogoutButton/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/client/components/Loyalty/LoyaltyProgress.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/client/components/Loyalty/LoyaltySidebarProgress.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/client/components/Partnerships/AnimatedCarousel.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/client/components/Partnerships/PartnershipsMenuContainer.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/client/components/SearchButton/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/client/components/ThemeSwitcher/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/client/components/ToggleButton.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/external/components/Tag.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/server/components/BottomProfile/components/Slider/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/server/components/BottomProfile/MainPanel/Balance/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/server/components/BottomProfile/MainPanel/Cashier/ActionLine/ActionButton.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/server/components/BottomProfile/MainPanel/Cashier/Info/InfoWrapper.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/server/components/BottomProfile/MainPanel/Header/Controls.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/server/components/BottomProfile/MainPanel/InviteBanner/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/server/components/BottomProfile/MainPanel/Popular/CategoryMore.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/server/components/BottomProfile/MainPanel/Settings/LanguageItem/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/server/components/BottomProfile/MainPanel/Settings/LogOutItem/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/server/components/BottomProfile/MainPanel/Settings/NotificationItem/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/server/components/BottomProfile/MainPanel/Support/SupportChat.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/server/components/BottomProfile/MainPanel/Support/SupportItem.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/server/components/ExternalScripts/LiveChatScript/ProtoScriptV3/ProtoClientScriptV3/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/server/components/Featurebase/FeaturebaseWrapper.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/server/components/Footer/LogosSection/EmpireLicense/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/server/components/LanguageGuard/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/server/components/LiveChat/LiveChatButton/LiveChatButtonClient/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/server/components/NavBar/LoggedInControls/UserControls/DepositLink/DepositButtonBottomMenu/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/server/components/NavBar/LoggedInControls/UserControls/DepositLink/DepositButtonDesktop/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/server/components/NavBar/LoggedInControls/UserControls/UserBalance/Balance/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/server/components/NavBar/LoggedInControls/UserControls/UserBalance/CashierLink/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/server/components/Sidebar/BottomLinksSection/WrapperLink.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/server/components/Sidebar/Section/AsideLinkWrap.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/server/components/Sidebar/UserItem/UserWrap.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/server/components/Sidebar/WrapperLogo.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/server/components/VipRefContainer/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/server/features/Loyalty/LoyaltySidebarLink/Wrapper.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/shared/components/Link/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js")